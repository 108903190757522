import "styles/pages/clients.scss";
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';

import React, { useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import { graphql } from "gatsby";

import Layout from "components/Layout";
import ImagesBar from "components/ImagesBar";
import Testimonials from "../page_components/clients/Testimonials";

const Clients = ({ data }) => {

  const [testimonialsSlideIndex, setTestimonialsSlideIndex ] = useState(1);

  const header_setup = {logo:'logo_black.svg', fontColor:'#000000', navbarColor: '#ffffff'};
  const seo = data.pageClients?.nodes[0]?.seo;
  const clients_page_data = data.pageClients?.nodes[0]?.pageClients;

  SwiperCore.use([Autoplay, Navigation]);

  const params = {
    loop: true,
    autoplay: {
      delay: 6000
    },
    slidesPerView: 1,
    centeredSlides: true,
    scrollbar: {
      draggable: true,
    },
    spaceBetween: 5,
    navigation: {
      nextEl: '.slider-nav__next',
      prevEl: '.slider-nav__prev'
    },
    onSlideChange: (e) => {
      setTestimonialsSlideIndex(e.realIndex + 1);
    },
  }

    return (
      <Layout seo={seo} header_setup={header_setup}>
        <section className="clients">
          <div className="container-fluid">
            <div className="site-container">
              <div className="section-title">{clients_page_data.testimonialsSectionTitle}</div>
              <div className="clients__wrapper">
                <p>{clients_page_data.testimonialsSectionText}</p>
                <img src={clients_page_data.testimonialsSectionLogos.sourceUrl} alt={clients_page_data.testimonialsSectionLogos.altText} />
              </div>
            </div>
          </div>
        </section>

        <section className="testimonials">
          <div className="section-title">{clients_page_data.testimonialsSection2Title}</div>
          <div className="testimonials__wrapper">
            <div className="container-fluid">
              <div className="site-container">
                <Swiper {...params} >
                  {clients_page_data.testimonialsSlides.map((slide, index) => {
                  
                    return (
                        <SwiperSlide key={index}>
                          <Testimonials slide={slide} />
                        </SwiperSlide>
                    )
                  })}
                  <div className="testimonials__slider-nav">
                    <div className="slider-nav__btn slider-nav__prev"> &#60; </div>
                    <div className="slider-nav__btn slider-nav__next"> &#62; </div>
                    <div className="slider-pagination">
                      {testimonialsSlideIndex} z {clients_page_data.testimonialsSlides.length}</div>
                  </div>
                </Swiper>
              </div>
            </div>
          </div>
        </section>

        <ImagesBar images={clients_page_data.clientsGallery} />
    </Layout>
  );
};

export const query = graphql`
  {
    pageClients: allWpPage(filter: {databaseId: {eq: 18}}) {
      nodes {
        seo {
          title
          metaDesc
        }
        pageClients {
          testimonialsSection2Title
          testimonialsSectionText
          testimonialsSectionTitle
          testimonialsSlides {
            ... on WpReferencja {
              testimonials {
                testimonialsAdditionalText
                testimonialsHeading
                testimonialsName
                testimonialsText
                testimonialsImg {
                  altText
                  sourceUrl
                }
              }
            }
          }
          testimonialsSectionLogos {
            altText
            sourceUrl
          }
          clientsGallery {
            galleryImg {
              altText
              sourceUrl
            }
          }
        }
      }
    }
  }
`

export default Clients;