import "./styles.scss";

import React from "react";

const Testimonials = ({ slide }) => {
  
  const {testimonialsText, testimonialsName, testimonialsHeading, testimonialsAdditionalText, testimonialsImg} = slide.testimonials;

      return (
            <div className="row">
              <div className="col-md-6 order-2 order-md-1 testimonials__col-left">
                <p className="testimonials__info">{testimonialsName}</p>
                <h2 className="testimonials__title">{testimonialsHeading}</h2>
                <div className="testimonials__text" dangerouslySetInnerHTML={{ __html: testimonialsText }} />
                <p className="testimonials__order">{testimonialsAdditionalText}</p>
              </div>
              <div className="col-md-6 order-1 order-md-2 testimonials__col-right">
                <div className="testimonials__img-wrapper">
                  <img
                    className="homepage-grid__image"
                    src={testimonialsImg.sourceUrl}
                    alt={testimonialsImg.altText}
                  />
                </div>
              </div>
            </div>
    );
  };
  
  export default Testimonials;